/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-t': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.332 6.35A.48.48 0 008 6.223a.459.459 0 00-.329.129c-1.025.976-1.913 1.945-2.888 2.942a.202.202 0 00.143.342h6.156a.2.2 0 00.144-.34c-.969-1.003-1.871-1.97-2.894-2.944z" _fill="#fff"/>'
  }
})
